<template>
  <div>
    <a-row style="padding:16px 0;background-color:#F5F5F5">
      <a-col :span="12" :offset="6">
        <a-steps :current="current" labelPlacement="vertical">
          <a-step title="选择技能包" />
          <a-step title="添写基本信息" />
        </a-steps>
      </a-col>
    </a-row>
    <SelectSkill v-show="current === 0" ref="selectskill" @change="handleChange" />
    <EditSkill v-show="current === 1" ref="editskill" @revert="handleRevert" />
  </div>
</template>

<script>
import SelectSkill from './components/SelectSkill'
import EditSkill from './components/EditSkill'
import * as goodsskillApi from '@/api/goodsskill'
export default {
  components: {
    SelectSkill,
    EditSkill
  },
  data() {
    return {
      current: 0,
      goodsId: ''
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      const {
        $route: {
          params: { id }
        }
      } = this
      this.goodsId = id
      goodsskillApi.detail(id).then(res => {
        this.skillId = res.data.skillId
        this.$refs.selectskill.initData(this.skillId)
      })
    },
    initNextData(data) {
      this.$refs.editskill.initData(data)
    },
    handleChange(query) {
      this.current = 1
      this.initNextData({ goodsId: this.goodsId, ...query })
    },
    handleRevert() {
      this.current = 0
    }
  }
}
</script>

<style lang="less" scoped></style>
